.container {
  background-color: #222;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapp-form {
  padding: 20px;
  width: 400px;
  height: 550px;
  border: 5px solid white;
  box-shadow: 0 5px 10px rgb(255, 255, 255);
  color: white;
}

.wrapp-fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 25px;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #FFF !important;
} */

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
  color: #FFF !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: #FFF !important;
}