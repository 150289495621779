.wrapp-not-register {
  padding: 3;
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
}

.wrapp-register {
  padding: 3;
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: purple !important;
}